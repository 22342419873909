import { ExclamationIcon } from '@heroicons/react/outline'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Button from 'app/components/Button'
import { HeadlessUiModal } from 'app/components/Modal'
import Typography from 'app/components/Typography'
import { useActiveWeb3React } from 'app/services/web3'
import React, { useState } from 'react'
import useSWR from 'swr'

const MULTICHAIN_ENDPOINT = 'https://bridgeapi.anyswap.exchange/approvals'

interface Allowance {
  token: string
  spender: string
  symbol: string
  type: string
  chainId: string
  allowance: string
}

interface MultiChainResponse {
  msg: 'Success' | 'Error'
  error?: string
  info?: Allowance[]
}

const fetcher = async (url: string): Promise<MultiChainResponse> => {
  const res = await fetch(url)
  return await res.json()
}

export const MultichainExploitAlertModal = () => {
  const { i18n } = useLingui()
  const { account } = useActiveWeb3React() // test account: '0xDB5367734Df4Aae8427b4064b15ADC12ceaE8f0b'
  const [open, setOpen] = useState(true)

  const { data, error } = useSWR(account ? `${MULTICHAIN_ENDPOINT}?account=${account}` : undefined, fetcher)

  if (!account || !data?.info?.length || error) return <></>

  return (
    <HeadlessUiModal.Controlled isOpen={open} onDismiss={() => setOpen(false)} maxWidth="sm">
      <div className="flex flex-col gap-4">
        <HeadlessUiModal.Header header={i18n._(t`Important notice`)} onClose={() => setOpen(false)} />
        <HeadlessUiModal.BorderedContent className="flex flex-col gap-2 bg-dark-1000/40 text-center">
          <ExclamationIcon width={100} className="self-center" />
          <Typography weight={700} variant="sm" className="text-secondary">
            {i18n._(
              t`Earlier this year, a critical vulnerability has been reported for Multichain contracts.
              It appears you may need to revoke approvals as soon as possible. Your tokens are potentially at risk.`
            )}
          </Typography>
        </HeadlessUiModal.BorderedContent>
        <Typography variant="xs" className="text-center text-secondary">
          <a
            href="https://medium.com/multichainorg/action-required-critical-vulnerability-for-six-tokens-6b3cbd22bfc0"
            target="_blank"
            rel="noreferrer"
          >
            {i18n._(t`You can read more about this here`)}
          </a>
        </Typography>
        <Button color="gradient" onClick={() => window.open('https://app.multichain.org/#/approvals', '_blank')}>
          {i18n._(t`Revoke Multichain approvals ↗`)}
        </Button>
      </div>
    </HeadlessUiModal.Controlled>
  )
}
