import { ChainId, Token } from '@sushiswap/core-sdk'
import config from 'config'

import * as BSC_TOKENS from './bsc'

// export * as ARBITRUM_TOKENS from './arbitrum'
// export * from './ethereum'
// export * as MATIC_TOKENS from './matic'
// export * as XDAI_TOKENS from './xdai'
// export * as BSC_TOKENS from './bsc'

export const GET_TOKEN = (chainId?: ChainId) => {
  if (!chainId) {
    return TOKENS[config.defaultChainId]
  }
  return TOKENS[chainId] ?? TOKENS[config.defaultChainId]
}

declare type AddressMap = {
  [chainId: number]: { [symbol: string]: Token }
}

const TOKENS: AddressMap = {
  [ChainId.BSC]: BSC_TOKENS,
}
