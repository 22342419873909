import { ChainId } from '@sushiswap/core-sdk'
import { NETWORK_ICON } from 'app/config/networks'
import { SUPPORTED_NETWORKS } from 'app/modals/NetworkModal'
import { useActiveWeb3React } from 'app/services/web3'
// @ts-ignore TYPE NEEDS FIXING
import cookie from 'cookie-cutter'
import Image from 'next/image'
import React from 'react'
import { Activity } from 'react-feather'

function Web3Network(): JSX.Element | null {
  const { chainId, library, account } = useActiveWeb3React()

  if (!chainId) return null

  return (
    <>
      {chainId.toString() === ChainId.BSC.toString() ? (
        <div className="flex items-center text-sm font-bold border-2 rounded border-dark-800 hover:border-dark-700 bg-dark-1000 hover:bg-dark-900 whitespace-nowrap">
          <div className="grid items-center grid-flow-col justify-center bg-dark-1000 h-[36px] w-[36px] text-sm rounded pointer-events-auto auto-cols-max text-secondary">
            {/*@ts-ignore TYPE NEEDS FIXING*/}
            <Image src={NETWORK_ICON[chainId]} alt="Switch Network" className="rounded-md" width="22px" height="22px" />
          </div>
        </div>
      ) : (
        <div
          className="flex items-center justify-center px-4 py-2 font-semibold text-white border rounded cursor-pointer pointer-events-auto select-none bg-opacity-80 border-red bg-red hover:bg-opacity-100"
          onClick={async () => {
            console.debug(`Switching to chain ${ChainId.BSC}`, SUPPORTED_NETWORKS[ChainId.BSC])
            const params = SUPPORTED_NETWORKS[ChainId.BSC]
            cookie.set('chainId', ChainId.BSC, params)

            try {
              await library?.send('wallet_switchEthereumChain', [{ chainId: `0x${ChainId.BSC.toString(16)}` }, account])
            } catch (switchError) {
              // This error code indicates that the chain has not been added to MetaMask.
              // @ts-ignore TYPE NEEDS FIXING
              if (switchError.code === 4902) {
                try {
                  await library?.send('wallet_addEthereumChain', [params, account])
                } catch (addError) {
                  // handle "add" error
                  console.error(`Add chain error ${addError}`)
                }
              }
              console.error(`Switch chain error ${switchError}`)
              // handle other "switch" errors
            }
          }}
        >
          <div className="mr-1">
            <Activity className="w-4 h-4" />
          </div>
        </div>
      )}
    </>
  )
}

export default Web3Network
