const AVALANCHE_CHAINLINK_MAPPING = {
  // AAVE / USD
  '0x3CA13391E9fb38a75330fb28f8cc2eB3D9ceceED': {
    from: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  // AVAX / USD
  '0x0A77230d17318075983913bC2145DB16C7366156': {
    from: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  // WBTC / USD
  '0x2779D32d5166BAaa2B2b658333bA7e6Ec0C65743': {
    from: '0x50b7545627a5162F82A992c33b87aDc75187B218',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 8,
    toDecimals: 8,
  },
  // DAI / USD
  '0x51D7180edA2260cc4F6e4EebB82FEF5c3c2B8300': {
    from: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  // ETH / USD
  '0x976B3D034E162d8bD72D6b9C989d545b839003b0': {
    from: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  // LINK / USD
  '0x49ccd9ca821EfEab2b98c60dC60F518E765EDe9a': {
    from: '0x5947BB275c521040051D82396192181b413227A3',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  // USDC / USD
  '0xF096872672F44d6EBA71458D74fe67F9a77a23B9': {
    from: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 6,
    toDecimals: 8,
  },
  // USDT / USD
  '0xEBE676ee90Fe1112671f19b6B7459bC678B67e8a': {
    from: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 6,
    toDecimals: 8,
  },
  // JOE / USD
  '0x02D35d3a8aC3e1626d3eE09A78Dd87286F5E8e3a': {
    from: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  // xJOE / USD
  '0x64e1E895866B3126f8f2E2912B475FDB35b2F315': {
    from: '0x57319d41F71E81F3c65F2a47CA4e001EbAFd4F33',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  // MIM / USD
  '0x54EdAB30a7134A16a54218AE64C73e1DAf48a8Fb': {
    from: '0x130966628846BFd36ff31a822705796e8cb8C18D',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  // SPELL / USD
  '0x4F3ddF9378a4865cf4f28BE51E10AECb83B7daeE': {
    from: '0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  // SUSHI / USD
  '0x449A373A090d8A1e5F74c63Ef831Ceff39E94563': {
    from: '0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
}

export default AVALANCHE_CHAINLINK_MAPPING
