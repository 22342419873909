import React, { FC } from 'react'

export const Portals: FC = () => {
  return (
    <>
      <span id="popover-portal" style={{ zIndex: 1000 }} />
    </>
  )
}

export default Portals
