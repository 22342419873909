const KOVAN_CHAINLINK_MAPPING = {
  '0xBc3f28Ccc21E9b5856E81E6372aFf57307E2E883': {
    from: '0x162c44e53097e7B5aaE939b297ffFD6Bf90D1EE3',
    to: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    decimals: 18,
    fromDecimals: 18,
    toDecimals: 18,
  },
  '0x24D6B177CF20166cd8F55CaaFe1c745B44F6c203': {
    from: '0x162c44e53097e7B5aaE939b297ffFD6Bf90D1EE3',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  '0x64EaC61A2DFda2c3Fa04eED49AA33D021AeC8838': {
    from: '0xb7a4F3E9097C08dA09517b5aB877F7a917224ede',
    to: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    decimals: 18,
    fromDecimals: 6,
    toDecimals: 18,
  },
  '0x9211c6b3BF41A10F78539810Cf5c64e1BB78Ec60': {
    from: '0xb7a4F3E9097C08dA09517b5aB877F7a917224ede',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 6,
    toDecimals: 8,
  },
  '0x22B58f1EbEDfCA50feF632bD73368b2FdA96D541': {
    from: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa',
    to: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    decimals: 18,
    fromDecimals: 18,
    toDecimals: 18,
  },
  '0x777A68032a88E5A84678A77Af2CD65A7b3c0775a': {
    from: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  '0x0bF499444525a23E7Bb61997539725cA2e928138': {
    from: '0x07de306FF27a2B630B1141956844eB1552B956B5',
    to: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    decimals: 18,
    fromDecimals: 6,
    toDecimals: 18,
  },
  '0x2ca5A90D34cA333661083F89D831f757A9A50148': {
    from: '0x07de306FF27a2B630B1141956844eB1552B956B5',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 6,
    toDecimals: 8,
  },
  '0xECF93D14d25E02bA2C13698eeDca9aA98348EFb6': {
    from: '0x61460874a7196d6a22D1eE4922473664b3E95270',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
  '0x0e4fcEC26c9f85c3D714370c98f43C4E02Fc35Ae': {
    from: '0x482dC9bB08111CB875109B075A40881E48aE02Cd',
    to: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    decimals: 18,
    fromDecimals: 18,
    toDecimals: 18,
  },
  '0xF7904a295A029a3aBDFFB6F12755974a958C7C25': {
    from: '0xd3A691C852CDB01E281545A27064741F0B7f6825',
    to: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    decimals: 18,
    fromDecimals: 8,
    toDecimals: 18,
  },
  '0x6135b13325bfC4B00278B4abC5e20bbce2D6580e': {
    from: '0xd3A691C852CDB01E281545A27064741F0B7f6825',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 8,
    toDecimals: 8,
  },
  '0x3A7e6117F2979EFf81855de32819FBba48a63e9e': {
    from: '0x50DD65531676F718B018De3dc48F92B53D756996',
    to: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    decimals: 18,
    fromDecimals: 18,
    toDecimals: 18,
  },
  '0x8f4e77806EFEC092A279AC6A49e129e560B4210E': {
    from: '0x50DD65531676F718B018De3dc48F92B53D756996',
    to: '0x0000000000000000000000000000000000000001',
    decimals: 8,
    fromDecimals: 18,
    toDecimals: 8,
  },
}

export default KOVAN_CHAINLINK_MAPPING
