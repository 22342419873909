import { ChainId } from '@sushiswap/core-sdk'
import BigNumber from 'bignumber.js'
import config from 'config'

export declare type StakerAddressInterface = {
  multiFeeDistributionAddress: string
  masterChefAddress: string
  // protocolOwnedDEXLiquidityTreasuryAddress: string,
  aaveOracleAddress: string
  lendingPoolAddressesProviderAddress: string
  uiPoolDataProvider: string
  lendingPoolAddress: string
  wETHGatewayAddress: string
  chefIncentivesControllerAddress: string
  aaveProtocolDataProviderAddress: string
  uiDataCalculator: string

  exchangeName: string
  swapUrl: string
  addLiquidityUrl: string

  loopAddress: string
}

export declare type AddressMap = {
  [chainId: number]: StakerAddressInterface
}

export declare type LoopableAddressMap = {
  [chainId: number]: string[]
}

export const GET = (chainId?: ChainId): StakerAddressInterface => {
  if (!chainId) {
    return STAKE_ADDRESS[config.defaultChainId]
  }
  return STAKE_ADDRESS[chainId] ?? STAKE_ADDRESS[config.defaultChainId]
}

export const GET_LOOPABLE = (chainId?: ChainId): string[] => {
  if (!chainId) {
    return LOOPABLE_TOKEN_ADDRESS[config.defaultChainId]
  }
  return LOOPABLE_TOKEN_ADDRESS[chainId] ?? LOOPABLE_TOKEN_ADDRESS[config.defaultChainId]
}

const STAKE_ADDRESS: AddressMap = {
  [ChainId.BSC]: {
    multiFeeDistributionAddress: '0xd4F7F739488f5C9930A60e85afbE26a8B71BA795', //a
    masterChefAddress: '0x18542eEe45272a29BC572F0EdB727da4e3506DD2', //a
    // protocolOwnedDEXLiquidityTreasuryAddress: '0x9af5fa118D892379daC7A28EfA6c30F5E15871fB',
    aaveOracleAddress: '0x57872c27051719f3a09C3f5062739A96a71DFc7b', //a

    lendingPoolAddressesProviderAddress: '0x7BC540aC806f2D013CEf106497750716EabeDFA3', //a
    uiPoolDataProvider: '0xd023903b478b9D303780A8eC4359900AA153a0cB', //a
    lendingPoolAddress: '0xC1D186CfFe83B35C921710a7ff433448e855FD1B', //a
    wETHGatewayAddress: '0xdE3EB79B46d32152D71540F221F560cF08b1b4E1', //a
    chefIncentivesControllerAddress: '0x19A392F8Fa8fFEE07106200236F5B7078860d51c', //a
    aaveProtocolDataProviderAddress: '0xf6c2C260f9AECE15DB2238295FfBB5a6BcF69A7f', //a
    uiDataCalculator: '0x046e36C244a029dE174C1962976535a0E06e14c2', //a

    exchangeName: 'PancakeSwap',
    swapUrl: 'https://pancakeswap.finance/swap?inputCurrency=BNB',
    addLiquidityUrl: 'https://pancakeswap.finance/add/BNB',

    loopAddress: '0x1Ef0B7342A46db7441E3afd03975DC2Ca2db6ea3', //a
  },
}

const LOOPABLE_TOKEN_ADDRESS: LoopableAddressMap = {
  [ChainId.BSC]: [
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // BUSD
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', // DAI
    '0x55d398326f99059fF775485246999027B3197955', // USDT
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', // USDC
  ],
}
